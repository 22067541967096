import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'chCurrency',
    standalone: false
})
export class ChCurrencyPipe implements PipeTransform {
    constructor(
        private currencyPipe: CurrencyPipe
    ) {

    }
    transform(value: any, ...args: any[]): string {
        return this.currencyPipe.transform(value, 'CHF ').replace(/,/g, '\'');
    }
}
